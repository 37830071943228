import { Box, Button, Modal, Typography } from "@mui/material";

import { ADD_AN_IMAGE_PATH, COPY_IMAGE_URL_PATH } from "../constants";
import { useToggle } from "../hooks/useToggle";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  maxWidth: "1000px",
  maxHeight: "95vh",
  overflowX: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const MODAL_TITLE_ID = "help-modal-title" as const;
const MODAL_DESCRIPTION_ID = "help-modal-description" as const;

export function Help() {
  const [isOpen, openModal, closeModal] = useToggle();
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        sx={{ mr: 4 }}
        onClick={openModal}
      >
        Help
      </Button>
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby={MODAL_TITLE_ID}
        aria-describedby={MODAL_DESCRIPTION_ID}
      >
        <Box sx={modalStyles}>
          <Typography id="help-modal-title" variant="h3" component="h2">
            How to use this CMS
          </Typography>
          <Typography id="help-modal-description">
            <p style={{ fontSize: "1.2em" }}>
              The purpose of this CMS is to manage content on{" "}
              <a href="https://info.fortum.com" target="_blank">
                info.fortum.com
              </a>{" "}
              (website for public communications) and{" "}
              <a href="https://intrainfo.fortum.com" target="_blank">
                intrainfo.fortum.com
              </a>{" "}
              (website for internal communications).
            </p>
            <Typography variant="h4" component="h3" sx={{ mt: 4 }}>
              How to fix "Insufficient permissions" error
            </Typography>
            <p>
              If you see "Error fetching data from the data source. Missing or
              insufficient permissions", you can fix it by logging out and back
              in again.
            </p>
            <Typography variant="h4" component="h3" sx={{ mt: 4 }}>
              When content changes are visible on the websites
            </Typography>
            <p>
              When{" "}
              <a href="https://intrainfo.fortum.com" target="_blank">
                internal site
              </a>{" "}
              content is updated, the changes are visible on the website
              immediately.
            </p>
            <p>
              When{" "}
              <a href="https://info.fortum.com" target="_blank">
                public site
              </a>{" "}
              content is updated, it takes a few minutes for changes to become
              visible. This is because the public site is re-built and
              re-deployed whenever its content changes, so that the site loads
              as fast as possible for end users. The current status of the
              public website can be viewed in the CMS header (next to the "Help"
              button). Common values for the status are below.
            </p>
            <ul>
              <li>
                "QUEUED" - this means a change was detected in the last few
                seconds, and the site is waiting to be built.
              </li>
              <li>
                "WORKING. Last deploy began at (time) on (date)" - the site is
                currently being generated, and a new version should be live soon
                (usually around 3 minutes after this message).
              </li>
              <li>
                "SUCCESS. Last deploy completed at (time) on (date)" - the site
                is currently live, and the last deploy completed successfully.
                This is the "normal" state.
              </li>
            </ul>
            <p>
              The message is updated every minute, but only when the CMS website
              is open. You can also refresh the page to update the message.
            </p>
            <p>
              Other than these differences in how changes become visible, the
              way the content is managed is identical for both sites.
            </p>
            <Typography variant="h4" component="h3" sx={{ mt: 4 }}>
              Homepage content
            </Typography>
            <p>
              Homepage content for each website has sections{" "}
              <strong>main heading</strong> and
              <strong>content</strong>. The main heading is the title of the
              page and is required. The content is optional, and can be used to
              add arbitrary text content and images using the built-in editor.
            </p>
            <p>
              Because there must be exactly one homepage for each site, the
              homepage data can only be edited, and not deleted or added.
            </p>
            <Typography variant="h4" component="h3" sx={{ mt: 4 }}>
              Pages content
            </Typography>
            <p>
              Both sites also have a "pages" section for custom page content.
              Pages contain the properties listed below. All properties are
              required.
            </p>
            <ul>
              <li>
                <strong>ID</strong>: An autogenerated ID for the page, that
                cannot be changed.
              </li>
              <li>
                <strong>Title</strong>: This is used as the main title for the
                page, and also the URL. e.g. the title "Example Title" would
                become "example-title" in the URL.
              </li>
              <li>
                <strong>Header image</strong>: This is an image that is used as
                the header image for the page.
              </li>
              <li>
                <strong>Content</strong>: This is the main content for the page.
                There is a built-in editor for this content, with some limited
                preview functionality too.
              </li>
              <li>
                <strong>Published</strong>: This is a toggle switch that
                determines whether the page is visible on the website. When
                creating a new page, it's recommended to ensure it's toggle OFF
                (grey) until the page is ready, and then toggle it ON (green).
              </li>
            </ul>
            <Typography variant="h4" component="h3" sx={{ mt: 4 }}>
              Adding images to content
            </Typography>
            <p>
              Images can also be added both the homepage and custom pages within
              the markdown editor. Images can be an external link, such as
              https://example.com/your-image.png, or an image that is uploaded
              to the CMS.
            </p>
            <p>
              To use an internal image within page content, first add the image
              to the CMS from the "Images" section, which is linked from the
              homepage. Images that are larger than 1,5MB will not be accepted.
              If the image is too large, sites such as{" "}
              <a href="https://kraken.io/web-interface" target="_blank">
                Kraken.io
              </a>
              {" or "}
              <a href="https://squoosh.app/" target="_blank">
                Squoosh.app
              </a>{" "}
              can be used to compress the image.
            </p>
            <p>
              Once an image has been added, you can copy its url from the button
              below the image like so:
            </p>
            <img
              src={COPY_IMAGE_URL_PATH}
              height="auto"
              width="800px"
              style={{ maxWidth: "100%" }}
            />
            <p>
              Once you have a link to an image that you would like to add, click
              the relevant button in the markdown editor. Be sure to add
              descriptive alt text, as it is important for accessibility.
            </p>
            <img
              src={ADD_AN_IMAGE_PATH}
              height="auto"
              width="800px"
              style={{ maxWidth: "100%" }}
            />
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
