import { getApps, initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

import {
  BuildStatus,
  FIREBASE_FUNCTIONS_REGION,
  GetSMSStatusResponse,
  GetUserCountsError,
  GetUserCountsSuccess,
  ISODateString,
  SendSMSParams,
  SendSMSResponse,
} from "../../../shared";

import { firebaseConfig } from "./firebase.config";

const apps = getApps();
const firebaseApp = apps.length ? apps[0] : initializeApp(firebaseConfig);

const functions = getFunctions(firebaseApp, FIREBASE_FUNCTIONS_REGION);

export const getPublicSiteBuildStatus = httpsCallable<
  // Params:
  never,
  // Returns (after promise resolves):
  {
    status: BuildStatus;
    startTime: ISODateString;
    finishTime: ISODateString;
  }
>(functions, "getPublicSiteBuildStatus");

export const getUserCounts = httpsCallable<
  // Params:
  never,
  // Returns (after promise resolves):
  GetUserCountsSuccess | GetUserCountsError
>(functions, "getUserCounts");

export const sendSMS = httpsCallable<
  // Params:
  SendSMSParams,
  // Returns (after promise resolves):
  SendSMSResponse
>(functions, "sendSMS");

export const getSMSStatus = httpsCallable<
  // Params:
  never,
  // Returns (after promise resolves):
  GetSMSStatusResponse
>(functions, "getSMSStatus");
