import { FormEvent, useState } from "react";
import { Box, Button, Modal, Tab, Tabs, Typography } from "@mui/material";

import { UserCounts } from "../../../shared";
import { getUserCounts, sendSMS } from "../firebase/firebase.functions";
import { useToggle } from "../hooks/useToggle";

import { SMSInstructions } from "./SMSInstructions";
import { SMSStatus } from "./SMSStatus";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  maxWidth: "800px",
  minHeight: "780px",
  maxHeight: "95vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  py: 6,
} as const;

const MODAL_TITLE_ID = "send-sms-modal-title" as const;

const USER_GROUPS: ReadonlyArray<keyof UserCounts | "phoneNumber"> = [
  "fortumUsers",
  "partnerUsers",
  "cmsUsers",
  "phoneNumber",
] as const;

const USER_GROUP_LABELS: Record<keyof UserCounts | "phoneNumber", string> = {
  fortumUsers: "Fortum employees",
  partnerUsers: "External consulants",
  cmsUsers: "CMS admins",
  phoneNumber:
    "A single phone number in international format (e.g. +358123456789): ",
};

export const SMS_160 = 160 as const; // 160 chars === 1 SMS
export const MAX_MESSAGE_LENGTH = SMS_160 * 2; // ...but messages can be composed of multiple SMSes

export function SendSMS() {
  const [isOpen, openModal, closeModal] = useToggle();
  const [activeTab, setActiveTab] = useState(0);

  const [userCounts, setUserCounts] = useState<UserCounts | null>(null);
  const getCounts = async () => {
    try {
      const { data } = await getUserCounts();
      if ("error" in data) {
        throw "Firebase function returned an error.";
      } else {
        setUserCounts(data);
      }
    } catch (error) {
      console.log(error);
      setUserCounts(null);
    }
  };

  const [selectedGroup, setSelectedGroup] = useState<
    (typeof USER_GROUPS)[number] | null
  >(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  const selectedUserCount =
    userCounts && selectedGroup
      ? selectedGroup === "phoneNumber"
        ? 1
        : userCounts[selectedGroup]
      : 0;
  const isMessageTooLong = message.length > MAX_MESSAGE_LENGTH;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSending(true);

    if (!userCounts || !selectedUserCount || !selectedGroup || !message) {
      alert("You must select a user group and enter a message.");
      setIsSending(false);
      return;
    }

    if (
      selectedGroup === "phoneNumber" &&
      // Basic phone number validation - the backend will do more
      (!phoneNumber?.trim() || !/^\+\d+$/.test(phoneNumber))
    ) {
      alert(
        "You selected 'Phone number' but did not enter a valid phone number.",
      );
      setIsSending(false);
      return;
    }

    if (isMessageTooLong) {
      alert("Message is too long. Rewrite it and try again.");
      setIsSending(false);
      return;
    }

    const confirmText = `Are you sure you want to send the message below to ${selectedUserCount} user${
      selectedUserCount > 1 ? "s" : ""
    }?\n"${message}"`;

    const didConfirm = confirm(confirmText);

    if (didConfirm) {
      sendSMS(
        selectedGroup === "phoneNumber"
          ? {
              userGroup: selectedGroup,
              message,
              phoneNumber,
            }
          : {
              userGroup: selectedGroup,
              message,
              phoneNumber: undefined,
            },
      )
        .then((response) => {
          if ("success" in response.data) {
            alert("Message successfully queued for sending.");
          } else {
            throw response.data?.error || "Unknown error.";
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Message sending failed.");
        })
        .finally(() => {
          setIsSending(false);
        });
    } else {
      setIsSending(false);
      alert("Sending cancelled.");
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        sx={{ mr: 2 }}
        onClick={() => {
          if (!isOpen) {
            void getCounts();
          }
          openModal();
        }}
      >
        Send SMS
      </Button>
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby={MODAL_TITLE_ID}
      >
        <Box sx={modalStyles}>
          <Box maxWidth={600} mx="auto">
            <Typography id={MODAL_TITLE_ID} variant="h3" component="h2">
              SMS messages
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={(event: React.SyntheticEvent, newTab: number) => {
                  setActiveTab(newTab);
                }}
                aria-label="Choose tab"
                sx={{ marginTop: 4 }}
                component="div"
              >
                <Tab label="Instructions" value={0} />
                <Tab label="Send" value={1} />
                <Tab label="View status" value={2} />
              </Tabs>
            </Box>

            {/**
             * Instructions tab
             * */}
            {activeTab === 0 && <SMSInstructions />}

            {/**
             * "Send" tab
             * */}
            {activeTab === 1 && (
              <Box mt={2}>
                {!userCounts && (
                  <Typography fontSize="1.2em">Loading...</Typography>
                )}
                {userCounts && (
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="usergroup">Selected users:</label>
                    {USER_GROUPS.map((userGroup) => (
                      <div
                        id="usergroup"
                        key={userGroup}
                        style={{ margin: "8px 0" }}
                      >
                        <input
                          type="radio"
                          id={userGroup}
                          name="userGroup"
                          value={userGroup}
                          checked={selectedGroup === userGroup}
                          onChange={() => setSelectedGroup(userGroup)}
                        />
                        <label htmlFor={userGroup}>
                          {USER_GROUP_LABELS[userGroup]}{" "}
                          {userGroup !== "phoneNumber" &&
                            `(${userCounts[userGroup]} users)`}
                        </label>
                        {userGroup === "phoneNumber" && (
                          <input
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) =>
                              setPhoneNumber(e.target.value.toString())
                            }
                            disabled={selectedGroup !== "phoneNumber"}
                            style={{
                              marginLeft: "16px",
                            }}
                          />
                        )}
                      </div>
                    ))}

                    <label
                      htmlFor="message"
                      style={{
                        color: isMessageTooLong ? "red" : "",
                      }}
                    >
                      {`Message (${message.length} / ${MAX_MESSAGE_LENGTH} characters):`}
                    </label>
                    <textarea
                      name="message"
                      placeholder="Enter your message here..."
                      rows={4}
                      onChange={(e) => setMessage(e.target.value)}
                      style={{
                        marginTop: "8px",
                        outline: isMessageTooLong ? "2px solid red" : "",
                        width: "100%",
                      }}
                    />

                    <button
                      type="submit"
                      disabled={!selectedGroup || !message || isSending}
                      style={{
                        fontSize: "1.2em",
                        marginTop: "16px",
                        cursor:
                          !selectedGroup || !message
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      {isSending
                        ? `Sending...`
                        : `Send message to ${selectedUserCount} user${
                            selectedUserCount === 1 ? "" : "s"
                          }`}
                    </button>
                  </form>
                )}
              </Box>
            )}

            {/**
             * "Status" tab
             * */}
            {activeTab === 2 && (
              <Box mt={2}>
                <SMSStatus />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
}
