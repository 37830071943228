import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { MessageStatus } from "../../../shared";
import { getSMSStatus } from "../firebase/firebase.functions";

const unixSecondsToString = (seconds: number) => {
  return new Date(seconds * 1000).toLocaleString("fi-FI");
};

const TableCell = (props: any) => <MuiTableCell {...props} align="center" />;

export function SMSStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState<MessageStatus[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getMessages = async () => {
    try {
      const { data } = await getSMSStatus();
      if ("error" in data) {
        throw "Firebase function returned an error.";
      } else {
        console.log(data.messages);
        setMessages(data.messages);
        setError(null);
      }
    } catch (error) {
      console.log(error);
      setMessages(null);
      setError(typeof error === "string" ? error : "Unknown error.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void getMessages();
  }, []);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (error) {
    return (
      <>
        Error: <em>{error}</em>
      </>
    );
  }

  if (!messages?.length) {
    return <>No previous messages found.</>;
  }

  return (
    <>
      <ul>
        <li>Hover over the phone number to view message contents.</li>
        <li>
          Hover over an errored status to view the error code and message.
        </li>
      </ul>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Added</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Phone number</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.map(
              (
                {
                  body,
                  to,
                  state,
                  startTime,
                  endTime,
                  errorCode,
                  errorMessage,
                },
                index,
              ) => {
                const added = unixSecondsToString(startTime);
                const updated = unixSecondsToString(endTime);
                const status =
                  state === "SUCCESS" ? "✅" : state === "ERROR" ? "❌" : state;
                const errorInfo =
                  errorCode && errorMessage
                    ? `${errorCode}: ${errorMessage}`
                    : "";

                return (
                  <TableRow key={index}>
                    <TableCell>{added}</TableCell>
                    <TableCell>{updated}</TableCell>
                    <TableCell title={body}>{to}</TableCell>
                    <TableCell title={errorInfo}>{status}</TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
