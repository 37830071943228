import { useState } from "react";
import { Alert, CircularProgress } from "@mui/material";

import { getPublicSiteBuildStatus } from "../firebase/firebase.functions";
import { useInterval } from "../hooks/useInterval";

import {
  formatDate,
  formatTime,
  getSeverityLevel,
} from "./PublicSiteBuildStatus.helpers";

import type { BuildStatus } from "../../../shared";

export function PublicSiteBuildStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState<BuildStatus>("STATUS_UNKNOWN");
  const [startTime, setStartTime] = useState<string>("");
  const [finishTime, setFinishTime] = useState<string>("");

  const updatePublicSiteBuildStatus = async () => {
    try {
      if (!document.hasFocus()) return;

      setIsLoading(true);

      const {
        data: { status, startTime: start, finishTime: finish },
      } = await getPublicSiteBuildStatus();

      setStatus(status || "STATUS_UNKNOWN");
      setStartTime(start ? `${formatTime(start)} on ${formatDate(start)}` : "");
      setFinishTime(
        finish ? `${formatTime(finish)} on ${formatDate(finish)}` : "",
      );
    } catch (error) {
      console.log(error);
      setStatus("STATUS_UNKNOWN");
      setStartTime("");
      setFinishTime("");
    } finally {
      setIsLoading(false);
    }
  };

  const ONE_MINUTE = 60 * 1000;
  useInterval(updatePublicSiteBuildStatus as () => void, ONE_MINUTE, true);

  return (
    <Alert
      severity={getSeverityLevel(status)}
      icon={isLoading ? <CircularProgress size="1em" /> : undefined}
      sx={{ mr: 2 }}
    >
      {status === "STATUS_UNKNOWN"
        ? "Public site build status loading... "
        : `Public site build status: ${status as string}. `}
      {!startTime && !finishTime
        ? ""
        : finishTime
          ? `Last deploy completed at ${finishTime}.`
          : `Last deploy began at ${startTime}.`}
    </Alert>
  );
}
