import { format } from "date-fns";

import type { BuildStatus, ISODateString } from "../../../shared";

export const getSeverityLevel = (status: BuildStatus) => {
  switch (status) {
    case "STATUS_UNKNOWN":
    case "PENDING":
    case "QUEUED":
    case "WORKING":
      return "info";
    case "SUCCESS":
      return "success";
    case "FAILURE":
    case "INTERNAL_ERROR":
    case "TIMEOUT":
    case "CANCELLED":
    case "EXPIRED":
      return "error";
    default:
      return "info";
  }
};

const formatDateTimeOptions = {
  weekStartsOn: 1,
} as const;

export const formatDate = (buildTime: ISODateString): string => {
  return format(new Date(buildTime), "dd.MM.yyyy", formatDateTimeOptions);
};

export const formatTime = (buildTime: ISODateString): string => {
  return format(new Date(buildTime), "HH:mm", formatDateTimeOptions);
};
