import { Box, Typography } from "@mui/material";

import { MAX_MESSAGE_LENGTH, SMS_160 } from "./SendSMS";

const INTERNAL_WEBSITE_URL = "https://intrainfo.fortum.com" as const;

export function SMSInstructions() {
  return (
    <Box mt={2}>
      <Typography fontSize="1.2em">
        From here, you can send an SMS message to users of{" "}
        <a
          href={INTERNAL_WEBSITE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {INTERNAL_WEBSITE_URL.replace("https://", "")}
        </a>
        .
      </Typography>
      <Typography fontSize="1.1em" mt={2} component="ol">
        <li>Click the "Send" tab above.</li>
        <li>
          Choose which user group will receive the message. If you wish to send
          to more than one user group, you can send the message multiple times.
        </li>
        <li>
          Write your message. Please note that there is a{" "}
          <u>{MAX_MESSAGE_LENGTH}</u> character limit. Also, if your message is
          longer than <u>{SMS_160}</u> characters, it will be sent as one
          message but priced as two.
        </li>
        <li>
          Click the "Send" button. You will be asked to confirm. If you do, the
          message will be sent.
        </li>
        <li>
          You can view the status of previously sent SMS messages by clicking
          the "View status" tab above.
        </li>
      </Typography>

      <Box mt={2}>
        Pricing: As an example, to send a 159 character SMS message to 8000
        users would cost around 650€. For more information about pricing, please
        contact LA008328 Fortum Emergency Communication Web Site BT Service
        Manager.
      </Box>
    </Box>
  );
}
