import "./styles.css";

import {
  Authenticator,
  buildCollection,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";
import { User as FirebaseUser } from "firebase/auth";

import { MAX_SESSION_DURATION } from "./../../shared";
import { Help } from "./components/Help";
import { PublicSiteBuildStatus } from "./components/PublicSiteBuildStatus";
import { SendSMS } from "./components/SendSMS";
import { firebaseConfig } from "./firebase/firebase.config";
import {
  APP_NAME,
  LOGO_PATH,
  PRIMARY_COLOUR,
  SECONDARY_COLOUR,
  TEN_MINUTES,
} from "./constants";
import {
  imageSchema,
  internalHomepageContentSchema,
  internalPageSchema,
  publicHomepageContentSchema,
  publicPageSchema,
} from "./schemas";

export default function App() {
  const navigation: NavigationBuilder = ({
    user: _user,
    authController: _authController,
  }: NavigationBuilderProps) => {
    return {
      collections: [
        /**
         * Shared collections
         */
        buildCollection({
          group: "Shared",
          name: "Images",
          path: "images",
          schema: imageSchema,
          permissions: {
            edit: true,
            create: true,
            delete: true,
          },
        }),

        /**
         * Public website collections
         */
        buildCollection({
          group: "Public website (info.fortum.com)",
          name: "Public website homepage content",
          path: "public-homepage-content",
          schema: publicHomepageContentSchema,
          permissions: {
            edit: true,
            // There is only one homepage, so users cannot delete it or create more.
            create: false,
            delete: false,
          },
        }),
        buildCollection({
          group: "Public website (info.fortum.com)",
          name: "Public website pages",
          path: "public-pages",
          schema: publicPageSchema,
          permissions: {
            edit: true,
            create: true,
            delete: true,
          },
        }),

        /**
         * Internal website collections
         */
        buildCollection({
          group: "Internal website (intrainfo.fortum.com)",
          name: "Internal website homepage content",
          path: "internal-homepage-content",
          schema: internalHomepageContentSchema,
          permissions: {
            edit: true,
            // There is only one homepage, so users cannot delete it or create more.
            create: false,
            delete: false,
          },
        }),
        buildCollection({
          group: "Internal website (intrainfo.fortum.com)",
          name: "Internal website pages",
          path: "internal-pages",
          schema: internalPageSchema,
          permissions: {
            edit: true,
            create: true,
            delete: true,
          },
        }),
      ],
    };
  };

  const authentication: Authenticator<FirebaseUser> = ({
    user: _user,
    authController,
  }) => {
    // 10 mins before logout, notify the user they'll be logged out soon
    setTimeout(() => {
      alert(
        "Your login will expire in 10 minutes, please save your work and log out and back in to continue.",
      );
    }, MAX_SESSION_DURATION - TEN_MINUTES);

    // MAX_SESSION_DURATION after login, log the user out
    setTimeout(() => {
      authController.signOut();
      alert("You have been logged out. Please log back in to continue.");
    }, MAX_SESSION_DURATION);

    return true;
  };

  return (
    <FirebaseCMSApp
      firebaseConfig={firebaseConfig}
      name={APP_NAME}
      logo={LOGO_PATH}
      primaryColor={PRIMARY_COLOUR}
      secondaryColor={SECONDARY_COLOUR}
      fontFamily="FortumSans, sans-serif"
      navigation={navigation}
      authentication={authentication}
      signInOptions={["password"]}
      toolbarExtraWidget={
        <>
          <PublicSiteBuildStatus />
          <SendSMS />
          <Help />
        </>
      }
      LoginViewProps={{
        NoUserComponent: "No user found with that email.",
        disableSignupScreen: true,
      }}
    />
  );
}
